// App.js
import React from 'react';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeatureSection';
import 'tailwindcss/tailwind.css';
import ClientesSection from './components/ClientesSection';
import ContactFormSection from './components/ContactForm';
import Helmet from 'react-helmet'
import icon from './assets/images/logo.png'


function App() {
  return (
    <div className="bg-gray-100 font-sans">
      <Helmet>
        <title>
          Softway Brasil - Seu futuro começa aqui
        </title>
        <meta name="description" content="A trilha do futuro tecnológico é com a Softway." />
        <meta name="keywords" content="consultoria de TI, desenvolvimento de software, soluções empresariais, tecnologia da informação, serviços de TI, consultoria em tecnologia, software house, desenvolvimento web, desenvolvimento de aplicativos, automação de processos, análise de dados, inteligência artificial, machine learning, segurança cibernética, suporte técnico, gestão de projetos, integração de sistemas, transformação digital, desenvolvimento manaus, aplicativo manaus, fábrica de software, desenvolvimento site, consultoria de tecnologia, servicos de tecnologia" />
        <link rel='icon' href={icon}  />
      </Helmet>
      <Navbar />
      <HeroSection />
      <FeaturesSection />
      <ClientesSection />
      <ContactFormSection />
    </div>
  );
}
 
export default App;
