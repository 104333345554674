import { Container } from "@tsparticles/engine";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { useEffect, useState } from "react";
import HeroImage from "../assets/images/web.png";
export default function Cover() {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = async (container?: Container): Promise<void> => {
    
  };

  function scrollToContact() {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  }

  if (init) {
    return (
      <header id="header" className="bg-gradient-to-r from-indigo-600 to-cyan-700 text-white py-36 px-8 header">
        <div id="particles-js">
          <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={{
              fpsLimit: 120,
              interactivity: {
                events: {
                  onClick: {
                    enable: false,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                },
                modes: {
                  push: {
                    quantity: 1,
                  },
                  repulse: {
                    distance: 50,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: ["#fff"],
                },
                links: {
                  color: "#0000015f",
                  distance: 50,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                collisions: {
                  enable: true,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: true,
                  speed: 0.8,
                  straight: true,
                },
                number: {
                  density: {
                    enable: false,
                  },
                  value: 75,
                },
                opacity: {
                  value: 0.8,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 6 },
                },
              },
              detectRetina: true,
            }}
          />

          <div className="container mx-auto flex">
            <div>
              <p className="md:text-5xl font-bold mb-8 w-4/5">
                Estamos na vanguarda da evolução tecnológica, moldando o futuro
                e impulsionando o mundo de amanhã.
              </p>
              <p className="text-1xl font-semibold mb-8 w-2/3">
                Venha fazer parte dessa jornada rumo ao futuro
              </p>
              <div>
                <button
                  onClick={scrollToContact}
                  className="bg-white text-indigo-700 py-2 px-6 rounded-full font-semibold hover:bg-indigo-700 hover:text-white transition duration-300"
                >
                  Saiba Mais
                </button>
                {/* Conteúdo do seu aplicativo aqui */}
              </div>
            </div>
            <img
              className="w-1/4 hidden  lg:block transform scale-x-[-1]"
              alt="imagem de um computador com varias telas saindo dele, indicando diversos tipos de serviços que fazemos"
              src={HeroImage}
            />
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 h-16 overflow-hidden pointer-events-none">
          <svg
            className="w-full h-full"
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 120 C150 30 350 180 500 120 L500 150 L0 150 Z"
              fill="#ffffff"
            />
          </svg>
        </div>
      </header>
    );
  }

  return <></>;
}
