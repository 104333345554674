import React, { useEffect, useState } from "react";
import ContactImage from "../assets/images/contact.png";
import { FcPhoneAndroid } from "react-icons/fc";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WppLogo from '../assets/images/wpp-logo.png'

function ContactFormSection() {
  const [buttonPulsate, setButtonPulsate] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setButtonPulsate(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const handleEnviarClick = () => {
    if (!nome || !message) {
        toast.error(`${!nome ? 'Antes de enviar, que tal informar como devemos chamar você?' : 'Antes de enviar, que tal informar como podemos ajudar você e seu negócio?'}.`);
        return;
      }

    let finalMessage = `Olá, me chamo ${nome}. Gostaria de enviar a seguinte mensagem: ${message}.`;

    window.open(
      `https://api.whatsapp.com/send/?phone=559231900206&text=${finalMessage}&type=phone_number&app_absent=0`
    );
  };

  return (
    <section id="contact" className="bg-white px-12 py-12">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="max-w-lg mx-auto">
          <h2 className="text-4xl font-bold text-center mb-8 text-gray-900">
            Quer criar algo incrível conosco?
          </h2>
          <p className="text-lg font-semibold text-center mb-8 text-gray-800">
            Compartilhe suas ideias brilhantes ou desafios únicos conosco e
            vamos transformá-los em realidade juntos!
          </p>
          <img src={ContactImage} alt="imagem de contato" />
        </div>
        <div>
          <form className="bg-gray-100 shadow-2xl rounded px-12 pt-6 pb-8 mb-4">
            <div className="flex items-center mb-8">
              <h2 className="text-3xl font-bold mr-4">
                Estamos aguardando seu contato
              </h2>
              <FcPhoneAndroid className="text-7xl" />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="username"
              >
                Como devemos chamar você?
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="nome"
                type="text"
                placeholder="ex: João Silva"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Qual seu melhor email?
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder="Informe seu email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="message"
              >
                E o que gostaria de falar para a gente?
              </label>

              <textarea
                id="message"
                name="message"
                rows={4}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Como podemos ajudar você e seu negócio?"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="flex items-center justify-center">
              <button
                className={`w-full bg-green-500 text-white font-bold py-3 px-6 gap-2 flex justify-center align-center rounded-full focus:outline-none focus:shadow-outline transition duration-200 ${
                  buttonPulsate ? "animate-pulse" : ""
                }`}
                type="button"
                onClick={handleEnviarClick}
              >
                Enviar mensagem
                <img src={WppLogo} alt="whatsapp logo" width={28} />
              </button>
            </div>
          </form>
          <p className="text-center text-gray-500 text-xs">
            &copy;2024 Softway development. Todos os direitos reservados.
          </p>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}

export default ContactFormSection;
