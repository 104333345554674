// components/FeaturesSection.js
import React from "react";

import SiteImage from "../assets/images/site_development.png";
import CellImage from "../assets/images/cell_development.png";
import BigDataImage from "../assets/images/bigdata.png";
import PeopleProjectImage from "../assets/images/people_project.png";
import ManagementImage from "../assets/images/management.png";

function FeaturesSection() {
  return (
    <section id="services" className="bg-white px-12 py-20">
      <div className="container mx-auto h-auto">
        <h2 className="text-3xl font-semibold mb-12 text-center">
          Nossos Serviços
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-8">
          <div className="bg-gray-200 p-8 rounded-lg shadow-md items-center  flex-col flex  ">
            <div className="w-60">
              <img
                src={SiteImage}
                alt="Desenvolvimento web"
                className="w-full"
              />
            </div>
            <h3 className="text-xl font-semibold mb-4">Desenvolvimento Web</h3>
            <p className="text-gray-700">
              Desenvolvimento web que ultrapassa expectativas. Transforme sua
              presença online em algo extraordinário conosco. Desde sites
              corporativos até plataformas de comércio eletrônico, estamos aqui
              para tornar sua marca imparável na web.
            </p>
          </div>
          <div className="bg-gray-200 p-8 rounded-lg shadow-md items-center  flex-col flex  ">
            <div className="w-32">
              <img
                src={CellImage}
                alt="Desenvolvimento web"
                className="w-full"
              />
            </div>

            <h3 className="text-xl font-semibold mb-4">Android & IOS</h3>
            <p className="text-gray-700">
              Desenvolvimento mobile que redefine a experiência do usuário.
              Transforme sua visão em aplicativos inovadores e intuitivos,
              proporcionando mobilidade e praticidade aos seus usuários.
            </p>
          </div>
          <div className="bg-gray-200 p-8 rounded-lg shadow-md items-center  flex-col flex  ">
            <img
              src={BigDataImage}
              alt="Desenvolvimento web"
              className="w-72"
            />

            <h3 className="text-xl font-semibold mb-4">Big Data</h3>
            <p className="text-gray-700">Big Data que impulsiona decisões inteligentes. Converta dados em insights valiosos e estratégias poderosas, alavancando o poder da análise de dados para impulsionar o crescimento e a inovação.</p>
          </div>
          <div className="bg-gray-200 p-8 rounded-lg shadow-md items-center  flex-col flex  ">
            <img
              src={PeopleProjectImage}
              alt="Desenvolvimento web"
              className="w-44"
            />

            <h3 className="text-xl font-semibold mb-4">Consultoria Estratégica</h3>
            <p className="text-gray-700">Consultoria estratégica que orienta o caminho para o sucesso. Com experiência e insights especializados, ajudamos a definir e alcançar seus objetivos de negócios, fornecendo orientação personalizada e soluções sob medida para impulsionar seu crescimento e superar desafios.</p>
          </div>
          <div className="bg-gray-200 p-8 rounded-lg shadow-md items-center  flex-col flex  ">
            <img
              src={ManagementImage}
              alt="Desenvolvimento web"
              className="w-64"
            />

            <h3 className="text-xl font-semibold mb-4">Gestão Inteligente</h3>
            <p className="text-gray-700">Gestão que conduz ao sucesso. Nossa abordagem estratégica e focada em resultados oferece soluções eficazes para gerenciar e otimizar seus processos, permitindo que você alcance seus objetivos de negócios com confiança e eficiência.</p>
          </div>
        </div>
      </div>

    </section>
  );
}

export default FeaturesSection;

export {};
