import React from "react";
import SolutionImage from "../assets/images/clients/sol.png";
import FocusImage from "../assets/images/clients/fq.jpg";
import NSImage from "../assets/images/clients/ns.png";
import UpImage from "../assets/images/clients/up2u.jpeg";

function ClientesSection() {
  return (
    <section className="bg-gray-100 px-12 py-24 relative">
      <div className="container mx-auto relative z-1">
        <h2 className="text-3xl font-bold text-center mb-8">
          Parcerias de Sucesso
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <img
            src={SolutionImage}
            alt="Cliente 1"
            className="w-1/2 h-auto rounded-lg"
          />
          <img
            src={FocusImage}
            alt="Cliente 2"
            className="w-1/2 h-auto rounded-lg"
          />
          <img
            src={NSImage}
            alt="Cliente 3"
            className="w-1/2 h-auto rounded-lg"
          />
                    <img
            src={UpImage}
            alt="Cliente 3"
            className="w-1/2 h-auto rounded-lg"
          />
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 h-24 overflow-hidden pointer-events-none">
        <svg
          className="w-full h-full"
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 120 C150 30 150 180 500 80 L500 150 L0 150 Z"
            fill="#ffffff"
          />
        </svg>
      </div>
    </section>
  );
}

export default ClientesSection;
